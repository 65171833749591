import {Trans} from '@/plugins/Translation'
import RouterBase from "@/components/utility/RouterBase"

export default [
    {
        path: '/404',
        component: () => import("@/components/other/404NotFound")
    },
    {
        path: '/:lang?/',
        component: RouterBase,
        // beforeEnter: (to, from, next) => {
        //     if (to.params.lang !== undefined) {
        //         return Trans.routeMiddleware
        //     }
        //     next();
        // },
        beforeEnter: Trans.routeMiddleware,
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('@/components/home/HomePage')
            },
            {
                path: 'challenges',
                component: () => import('@/components/utility/RouterBase'),
                children: [
                    {
                        path: 'central-overview',
                        name: 'Central Overview',
                        component: () => import("@/components/industryChallenges/centralOverview/CentralOverviewHome")
                    },
                    {
                        path: 'geometric-defects',
                        name: 'Geometric Defects',
                        component: () => import("@/components/industryChallenges/geometricDefects/GeometricDefectsHome")
                    },
                    {
                        path: 'cosmetic-defects',
                        name: 'Cosmetic Defects',
                        component: () => import("@/components/industryChallenges/cosmeticDefects/CosmeticDefectsHome")
                    }
                ]
            },
            {
                path: 'products',
                component: () => import('@/components/utility/RouterBase'),
                children: [
                    {
                        path: 'ampoules',
                        name: 'Ampoules',
                        component: () => import("@/components/products/ampoules/AmpoulesProduct")
                    },
                    {
                        path: 'custom-geometries',
                        name: 'Custom',
                        component: () => import("@/components/products/custom/CustomProduct")
                    },
                    {
                        path: 'syringes',
                        name: 'Syringes',
                        component: () => import("@/components/products/syringes/SyringesProduct")
                    },
                    {
                        path: 'vials',
                        name: 'Vials',
                        component: () => import("@/components/products/vials/VialsProduct")
                    }
                ]
            },
            {
                path: 'solutions',
                component: () => import('@/components/utility/RouterBase'),
                children: [
                    {
                        path: 'comprehensive-solution',
                        name: 'Comprehensive Solution',
                        component: () => import("@/components/solutions/comprehensiveSolution/ComprehensiveSolution")
                    },
                    {
                        path: 'mechanical-handling',
                        name: 'Mechanical Handling',
                        component: () => import("@/components/solutions/mechanicalHandling/MechanicalHandling")
                    }
                ]
            },
            {
                path: 'contact',
                name: 'Contact',
                component: () => import("@/components/contact/ContactHome")
            },
            {
                path: 'about',
                name: 'About',
                component: () => import("@/components/about/AboutHome")
            },
            {
                path: 'imprint-and-privacy',
                name: 'Imprint',
                component: () => import("@/components/other/ImprintAndPrivacy")
            },
            {
                path: 'careers',
                name: 'Careers',
                component: () => import("@/components/careers/CareersHome")
            },
            {
                path: 'news',
                name: 'News',
                component: () => import("@/components/other/ImprintAndPrivacy")
            },
            {
                path: 'resources',
                name: 'Resources',
                component: () => import("@/components/other/CompanyResources")
            },
            {
                path: '*',
                component: () => import("@/components/other/404NotFound")
            }
        ]
    },
    // {
    //     // Redirect user to supported lang version.
    //     path: '*',
    //     redirect() {
    //         return Trans.getUserSupportedLang()
    //     }
    // }
]
