const SET_MOBILE = (state, isMobile) => {
    state.isMobile = isMobile
}

const SET_TABLET = (state, isTablet) => {
    state.isTablet = isTablet
}

const SET_DESKTOP = (state, isDesktop) => {
    state.isDesktop = isDesktop
}

const SET_COOKIE_PERMISSION = (state, permission) => {
    state.cookieStatus = permission
}

export default {
    SET_MOBILE,
    SET_TABLET,
    SET_DESKTOP,
    SET_COOKIE_PERMISSION
}
