import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, BIconArrowUpRight, BIconEnvelopeFill, BIconTelephoneFill, BIconChevronDown} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { i18n } from '@/plugins/i18n'
import { Trans } from '@/plugins/Translation'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faTimes, faMapMarker, faDesktop, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBars, faTimes, faMapMarker, faDesktop, faBriefcase)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import "@/styles/custom.scss"
import "@/styles/global.sass"

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.component('BIconEnvelopeFill', BIconEnvelopeFill)
Vue.component('BIconTelephoneFill', BIconTelephoneFill)
Vue.component('BIconChevronDown', BIconChevronDown)
Vue.component('BIconArrowUpRight', BIconArrowUpRight)

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

