<template>
  <b-nav-item-dropdown
      no-caret
      @show="$emit('setOpened', true)"
      @hide="$emit('setOpened', false)"
      ref="drop"
      :right=right
      :class="menuItem.opened ? 'opened-menu' : 'closed-menu'"
      class="mx-1 mx-md-3 drop">
    <template #button-content>
      <b-row class="pt-4 pb-2 m-0">
        <b-col cols="auto"
               :class="menuHover ? (menuItem.opened ? 'i-primary' : 'i-secondary') : 'i-primary'"
               @mouseover="$refs.drop.visible = true"
               class="mr-2 p-0 i-text-small"
        >
          {{ menuItem.title }}
        </b-col>
        <b-col cols="auto" class="p-0">
          <b-icon-chevron-down
              class="chevron-icon"
              :class="menuItem.opened ? 'open' : ''">
          </b-icon-chevron-down>
        </b-col>
      </b-row>
    </template>
    <template #default>
      <b-container
          fluid
          @mouseover="$refs.drop.visible = true"
          @mouseleave="$refs.drop.visible = false"
          @click="$refs.drop.visible = false"
          class="menu-dropdown rounded-0">
        <slot></slot>
      </b-container>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  props: {
    menuItem: Object,
    menuHover: Boolean,
    contactHover: Boolean,
    right: { // align menu to right side
      type: Boolean,
      default: false
    },
  },
  watch: {
    contactHover: function(v) {
      if (v === true)
      this.$refs.drop.visible = false
    }
  }
}
</script>
<style lang="sass" scoped>
.drop /deep/ .dropdown-menu
  padding: 0!important
  margin: 0!important
  border-radius: 0!important

.menu-dropdown
  width: 500px
  background: $primary-background
  box-shadow: 0 0 0 1pt $primary-color
  border-radius: 0 0 10px 10px
  overflow: hidden
  @media screen and (min-width: $lg)
    width: 530px

.opened-menu
  background: $primary-background

.chevron-icon
  transform: rotate(0deg)
  transition: transform .2s linear
  font-size: 65% !important
  margin-bottom: 5px
  color: $primary-color
  @media screen and (min-width: $xl)
    margin-bottom: 2px
  &.open
    transform: rotate(180deg)
    transition: transform .2s linear
</style>
