<template>
  <b-container fluid class="pb-4 pt-5 px-md-5 footer">
    <b-row class="justify-content-center">
      <b-col cols="4" class="logo-row">
        <router-link :to="$i18nRoute({ name: 'Home'})">
          <img alt="isotronic horizontal logo" height="30" class="logo" src='@/assets/images/logos/logo-horizontal.svg'>
        </router-link>
      </b-col>
      <b-col
          v-for="(header, index) in menuItems"
          :key="header.title + index"
          class="i-text-smaller i-font-text-bold i-ternary mb-5 mb-md-0"
          cols="6" sm="3" md="2"
      >
        {{ header.title }}
        <div
            v-for="(item, index) in header.menuItems"
            :key="item.title + index"
            class="i-text-smaller font-weight-normal py-1 py-sm-0"
        >
          <router-link
              :to="$i18nRoute({ name: item.page})"
              class="i-ternary i-font-text menu-items">
            {{ item.title }}
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-2 pt-md-5">
      <b-col order="5" order-md="0" cols="12" md="2" class="i-text-smaller mt-2 mt-md-0 i-ternary pb-3 pt-4 py-sm-0">
        2021 &#169; Isotronic
      </b-col>
      <b-col class="i-text-smaller i-ternary text-left text-md-center my-auto">
        <b-link href="https://www.linkedin.com/company/isotronic-gmbh" target="_blank">
          <b-img src="@/assets/icons/linkedin.svg" fluid alt="isotronic linkedin icon" width="25"></b-img>
        </b-link>
      </b-col>
      <b-col cols="6" sm="3" md="2" class="i-text-smaller i-ternary text-left p-0">
        <language-switcher v-if="0" class="language-switcher"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LanguageSwitcher from "@/components/footer/LanguageSwitcher"

export default {
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      menuItems: [
        {
          title: this.$t('footer.menu.menuItems.solutions.title'),
          menuItems: [
            {
              title: this.$t('footer.menu.menuItems.solutions.menu1'),
              page: "Comprehensive Solution"
            },
            {
              title: this.$t('footer.menu.menuItems.solutions.menu2'),
              page: "Mechanical Handling"
            },
          ]
        },
        {
          title: this.$t('footer.menu.menuItems.products.title'),
          menuItems: [
            {
              title: this.$t('footer.menu.menuItems.products.menu1'),
              page: "Vials"
            },
            {
              title: this.$t('footer.menu.menuItems.products.menu2'),
              page: "Syringes"
            },
            {
              title: this.$t('footer.menu.menuItems.products.menu3'),
              page: "Ampoules"
            },
            {
              title: this.$t('footer.menu.menuItems.products.menu4'),
              page: "Custom"
            },
          ]
        },
        {
          title: this.$t('footer.menu.menuItems.challenges.title'),
          menuItems: [
            {
              title: this.$t('footer.menu.menuItems.challenges.menu1'),
              page: "Cosmetic Defects"
            },
            {
              title: this.$t('footer.menu.menuItems.challenges.menu2'),
              page: "Geometric Defects"
            },
            {
              title: this.$t('footer.menu.menuItems.challenges.menu3'),
              page: "Central Overview"
            }
          ]
        },
        {
          title: this.$t('footer.menu.menuItems.company.title'),
          menuItems: [
            {
              title: this.$t('footer.menu.menuItems.company.menu1'),
              page: "About"
            },
            {
              title: this.$t('footer.menu.menuItems.company.menu2'),
              page: "Contact"
            },
            // {
            //   title: this.$t('footer.menu.menuItems.company.menu3'),
            //   page: "News"
            // },
            // {
            //   title: this.$t('footer.menu.menuItems.company.menu4'),
            //   page: "Resources"
            // },
            {
              title: this.$t('footer.menu.menuItems.company.menu6'),
              page: "Careers"
            },
            {
              title: this.$t('footer.menu.menuItems.company.menu5'),
              page: "Imprint"
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.footer
  background: linear-gradient(0deg, #E05A5A, #E05A5A), #FFFFFF

.logo
  filter: brightness(0) invert(1)

.logo-row
  @media screen and (max-width: $md)
    display: none

.menu-items
  opacity: 0.7
  &:hover
    opacity: 1
    color: white
</style>
