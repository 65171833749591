<template>
  <b-container fluid class="px-0">
    <navigation-desktop v-if="isDesktop" :menuItems="menuItems" @setMenuItemOpen="setOpen"/>
    <navigation-mobile :menuItems="menuItems" v-else/>
  </b-container>
</template>

<script>
import NavigationDesktop from '@/components/navigation/desktop/NavigationDesktop'
import NavigationMobile from '@/components/navigation/mobile/NavigationMobile'
import {mapState} from 'vuex'

export default {
  components: {
    NavigationDesktop,
    NavigationMobile
  },
  computed: mapState([
    'isDesktop'
  ]),
  methods: {
    setOpen(index, val) {
      this.menuItems[index].opened = val
    }
  },
  data() {
    return {
      menuItems: [
        {
          title: this.$t('navigation.solutions.title'),
          opened: false,
          menuItems: [
            {
              title: this.$t('navigation.solutions.comprehensive.title'),
              description: this.$t('navigation.solutions.comprehensive.description'),
              page: 'Comprehensive Solution'
            },
            {
              title: this.$t('navigation.solutions.mechanical.title'),
              description: this.$t('navigation.solutions.mechanical.description'),
              page: 'Mechanical Handling'
            },
          ]
        },
        {
          title: this.$t('navigation.products.title'),
          opened: false,
          menuItems: [
            {
              title: this.$t('navigation.products.vials.title'),
              page: 'Vials',
              img: {
                src: 'https://ik.imagekit.io/isotronic/navigation/vials_QkNq5pakAen.png?updatedAt=1635423047426',
                alt: this.$t('navigation.products.vials.alt')
              }
            },
            {
              title: this.$t('navigation.products.syringes.title'),
              page: 'Syringes',
              img: {
                src: 'https://ik.imagekit.io/isotronic/navigation/syringes_suqp3RI_o7.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665579461062',
                alt: this.$t('navigation.products.syringes.alt')
              }
            },
            {
              title: this.$t('navigation.products.ampoules.title'),
              page: 'Ampoules',
              img: {
                src: 'https://ik.imagekit.io/isotronic/navigation/ampoules_mnzD6_q_KX.png?updatedAt=1635423047932',
                alt: this.$t('navigation.products.ampoules.alt')
              }
            },
            {
              title: this.$t('navigation.products.custom.title'),
              page: 'Custom',
              img: {
                src: 'https://ik.imagekit.io/isotronic/navigation/custom_pN75c8lLoW.png?updatedAt=1635423047710',
                alt: this.$t('navigation.products.custom.alt')
              }
            }
          ]
        },
        {
          title: this.$t('navigation.challenges.title'),
          opened: false,
          menuItems: [
            {
              title: this.$t('navigation.challenges.cosmetic.title'),
              description: this.$t('navigation.challenges.cosmetic.description'),
              page: 'Cosmetic Defects'
            },
            {
              title: this.$t('navigation.challenges.geometric.title'),
              description: this.$t('navigation.challenges.geometric.description'),
              page: 'Geometric Defects'
            },
            {
              title: this.$t('navigation.challenges.central.title'),
              description: this.$t('navigation.challenges.central.description'),
              page: 'Central Overview'
            },
          ]
        }
      ]
    }
  }
}
</script>
