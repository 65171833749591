<template>
  <b-navbar class="mobile-navigation py-0">
    <router-link :to="$i18nRoute({ name: 'Home'})">
      <b-navbar-brand class="pl-0">
        <img alt="isotronic horizontal logo" height="30" src='@/assets/images/logos/logo-horizontal.svg'>
      </b-navbar-brand>
    </router-link>
    <div class="ml-auto my-auto">
      <font-awesome-icon v-b-toggle.menu-sidebar icon="bars" size="lg" class="bars-icon i-primary mr-2"/>
      <b-sidebar id="menu-sidebar"
                 class="mobile-menu"
                 width="768px"
                 shadow>
        <template #title>
          <router-link :to="$i18nRoute({ name: 'Home'})">
            <img alt="isotronic horizontal logo" class="sidebar-logo" height="30"
                 src='@/assets/images/logos/logo-horizontal.svg'>
          </router-link>
        </template>
        <template #header-close>
          <font-awesome-icon icon="times" size="lg" class="i-ternary"/>
        </template>
        <div class="px-3">
          <b-row v-for="(menu, index) in menuItems"
                 :key="menu.title + index"
          >
            <b-col cols="12">
              <div class="text-uppercase menu-sub-title muted i-font-text-bold mt-4">
                {{ menu.title }}
              </div>
              <div v-for="(item, index2) in menu.menuItems"
                   :key="item.title + index + index2"
                   class="my-2 i-ternary menu-item"
              >
                <router-link :to="$i18nRoute({ name: item.page})" tag="div">
                  {{ item.title }}
                </router-link>
              </div>
            </b-col>
          </b-row>
          <div class="mt-5">
            <div v-for="(item, index) in additionalMenu"
                 :key="item.title + index"
                 class="my-2 muted menu-item"
            >
              <router-link :to="$i18nRoute({ name: item.page})" tag="div">
                {{ item.title }}
              </router-link>
            </div>
          </div>
          <b-row class="lower-section w-100 mx-0 px-0">
            <b-col cols="6" sm="12" class="mx-0 px-0">
              <language-switcher/>
            </b-col>
            <b-col cols="6" sm="12" class="text-left text-sm-center my-auto px-0">
              <b-link href="https://www.linkedin.com/company/isotronic-gmbh" target="_blank">
                <b-img src="@/assets/icons/linkedin.svg" fluid alt="isotronic linkedin icon" width="25"></b-img>
              </b-link>
            </b-col>
            <div class="w-100"></div>
            <b-col cols="12" sm="auto" class="my-sm-auto my-5">
              <div class="i-text-smaller i-ternary copywrite mb-4">
                2021 &#169; Isotronic
              </div>
            </b-col>
            <b-col cols="12" sm="auto"
                   class="ml-auto my-sm-4 mb-0 py-3 py-sm-0 text-center text-sm-right contact contact-tablet">
              <div class="fixed-bottom mr-3 mb-4">
                <router-link :to="$i18nRoute({ name: 'Contact'})">
                  <b-button class="i-secondary-button text-uppercase i-font-text-bold contact-button">
                    {{ $t('navigation.other.contact') }}
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="contact fixed-bottom px-3 py-2">
          <router-link :to="$i18nRoute({ name: 'Contact'})">
            <b-button class="w-100 i-secondary-button text-uppercase i-font-text-bold contact-button contact-mobile">
              {{ $t('navigation.other.contact') }}
            </b-button>
          </router-link>
        </div>
      </b-sidebar>
    </div>
  </b-navbar>
</template>

<script>
import LanguageSwitcher from "@/components/footer/LanguageSwitcher";
import {mapState} from "vuex";

export default {
  components: {
    LanguageSwitcher
  },
  props: {
    menuItems: Array
  },
  computed: mapState([
    'isTablet',
    'isMobile'
  ]),
  data() {
    return {
      additionalMenu: [
        {
          title: this.$t('footer.menu.menuItems.company.menu1'),
          page: "About"
        },
        {
          title: this.$t('footer.menu.menuItems.company.menu3'),
          page: "News"
        },
        {
          title: this.$t('footer.menu.menuItems.company.menu4'),
          page: "Resources"
        },
        {
          title: this.$t('footer.menu.menuItems.company.menu5'),
          page: "Imprint"
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.mobile-navigation
  height: 60px

.bars-icon
  &:hover
    cursor: pointer

  &:focus
    outline: none

.mobile-menu /deep/ .b-sidebar
  background: $less-red !important

.sidebar-logo
  filter: brightness(0) invert(1)

.muted
  color: #FFE4DF99

.menu-sub-title
  font-size: 13px

.menu-item
  &:hover
    cursor: pointer
    color: #FFE4DF99

.copywrite
  opacity: 0.8

.lower-section
  margin-top: 65px

.contact
  background: white
  @media screen and (min-width: $sm)
    width: auto
    background: $primary-background

.contact-tablet
  display: none
  @media screen and (min-width: $sm)
    display: block

.contact-mobile
  display: block
  @media screen and (min-width: $sm)
    display: none

.contact-button
  border-radius: 20px
  @media screen and (min-width: $sm)
    border: none !important
</style>
