<template>
  <dropdown-menu
      :right="false"
      v-model="show"
      :closeOnClickOutside="true"
  >
    <button class="btn lang-menu"
            :class="show ? 'language-menu-open' : ''"
    >
      <b-img src="@/assets/icons/language.svg" fluid alt="isotronic linkedin icon" width="25" />
      <span class="i-font-text-bold i-text-smaller i-ternary ml-2">
        {{options.find(element => element.value === selected).text}}
      </span>
    </button>
    <div slot="dropdown">
<!--      todo: might need a router link to here-->
        <a class="dropdown-item i-less-red p-3"
            v-for="(option, index) in options"
            :key="'language'+index"
            @click="changeLanguage(option.value)"
        >
          {{option.text}}
        </a>
    </div>
  </dropdown-menu>
</template>

<script>
import {Trans} from "@/plugins/Translation";
import DropdownMenu from '@innologica/vue-dropdown-menu'

export default {
  components: {
    DropdownMenu
  },
  data() {
    return {
      show: false,
      selected: this.$i18n.locale,
      options: [
        {value: 'en', text: 'English'},
        {value: 'de', text: 'Deutsch'}
      ]
    }
  },
  methods: {
    changeLanguage(lang) {
      this.selected = lang
      const to = this.$router.resolve({params: {lang}})
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.lang-menu
  &:hover
    background-color: rgba(255,255,255,0.1)!important

.language-menu-open
  background-color: rgba(255,255,255,0.1)!important
  outline: none !important
  box-shadow: none !important
  &:hover:active:focus, &:active
    outline: none !important
    box-shadow: none !important
</style>

<style lang="sass">
.dropdown-menu
  border: 1px solid white!important
  border-radius: 10px!important
  margin-bottom: 0.5rem!important
  @media screen and (min-width: 1300px)
    left: auto!important

.dropdown-item
  &:hover
    border-radius: 10px
    cursor: pointer
    background-color: $less-red!important
    color: $ternary-color!important


</style>
