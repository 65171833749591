const getDefaultState = () => {
    return {
        isMobile: false,
        isTablet: false,
        isDesktop: false,
        cookieStatus: null
    }
}

module.exports = {
    getDefaultState
};
