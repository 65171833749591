<template>
  <router-link :to="$i18nRoute({ name: link})">
    <b-button class="text-uppercase i-text-small i-font-text-bold"
              :class="variant === 'primary' ? 'i-primary-button' : 'i-secondary-button'"
    >
      <slot>Fill me in</slot>
    </b-button>
  </router-link>
</template>

<script>
export default {
  props: {
    link: String,
    variant: {
      type: String,
      default: 'primary' // primary / secondary
    },
  }
}
</script>


