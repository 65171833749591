<template>
  <div id="app">
    <Navigation/>
    <router-view/>
    <vue-cookie
        v-if="showPopup"
        :ref="'cookiePanel'"
        :elementId="'cookePanel'"
        :debug="false"
        :position="'bottom'"
        :type="'bar'"
        :disableDecline="false"
        :transitionName="'slideFromBottom'"
        :showPostponeButton="false"
        @status="assignCookieStatus"
        @clicked-accept="cookieClickedAccept"
        @removed-cookie="cookieRemovedCookie"
        @clicked-decline="cookieClickedDecline">

      <div slot="message">
        We use cookies to ensure you get the best experience on our website.
        <router-link :to="$i18nRoute({ name: 'Imprint'})" tag="a">
          Learn More...
        </router-link>
      </div>

      <div slot="declineContent">
        Opt out
      </div>

      <div slot="acceptContent">
        Got it!
      </div>
    </vue-cookie>
    <Footer class="footer"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import Navigation from "@/components/navigation/NavigationHome";
import Footer from "@/components/footer/FooterHome"
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookie from 'vue-cookie-accept-decline'

export default {
  data() {
    return {
      window: {
        width: 0,
        mobileBreakpoint: 375,
        tabletBreakpoint: 768,
        desktopBreakpoint: 992
      },
      showPopup: true
    }
  },
  components: {
    Navigation,
    Footer,
    VueCookie
  },
  metaInfo() {
    return this.addGoogleAnalytics
  },
  created() {
    this.SET_MOBILE(window.innerWidth <= this.window.mobileBreakpoint)
    this.SET_TABLET(window.innerWidth <= this.window.tabletBreakpoint && window.innerWidth > this.window.mobileBreakpoint)
    this.SET_DESKTOP(window.innerWidth >= this.window.desktopBreakpoint)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile,
      isTablet: state => state.isTablet,
      isDesktop: state => state.isDesktop,
      cookieStatus: state => state.cookieStatus
    }),
    addGoogleAnalytics() {
      if (this.cookieStatus === 'accept') {
          return {
            script: [
              {src: 'https://www.googletagmanager.com/gtag/js?id=UA-134384103-1', async: true, defer: false},
              {src: 'https://www.google-analytics.com/analytics.js', async: true, defer: false, type: "text/javascript"},
              {
                innerHTML: 'window.dataLayer = window.dataLayer || [];\n' +
                    'function gtag(){dataLayer.push(arguments);}\n' +
                    'gtag(\'js\', new Date());\n' +
                    'gtag(\'config\', \'UA-134384103-1\');'
              }
            ],
          }
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_MOBILE',
      'SET_TABLET',
      'SET_DESKTOP',
      'SET_COOKIE_PERMISSION'
    ]),
    handleResize() {
      this.window.width = window.innerWidth
      if (this.window.width < this.window.mobileBreakpoint && !this.isMobile) {
        this.SET_MOBILE(true)
        this.SET_TABLET(false)
        this.SET_DESKTOP(false)
      } else if (this.window.width >= this.window.mobileBreakpoint && this.window.width < this.window.tabletBreakpoint && !this.isTablet) {
        this.SET_MOBILE(false)
        this.SET_TABLET(true)
        this.SET_DESKTOP(false)
      } else if (this.window.width >= this.window.tabletBreakpoint && this.window.width < this.window.desktopBreakpoint) {
        this.SET_MOBILE(false)
        this.SET_TABLET(false)
        this.SET_DESKTOP(false)
      } else if (this.window.width > this.window.desktopBreakpoint && (!this.isDesktop)) {
        this.SET_DESKTOP(true)
      }
    },
    assignCookieStatus(val) {
      if (val === 'decline') {
        this.$refs.cookiePanel.removeCookie()
      } else if (val === 'accept') {
        this.showPopup = false
      }
      this.SET_COOKIE_PERMISSION(val)
    },
    cookieClickedAccept() {
      console.log("user accepts cookies")
      this.SET_COOKIE_PERMISSION('accept')
      this.showPopup = false
    },
    cookieClickedDecline() {
      console.log("user denies cookies")
      this.SET_COOKIE_PERMISSION('decline')
      this.showPopup = false
    },
    cookieRemovedCookie() {
      console.log("removed cookie")
      this.SET_COOKIE_PERMISSION(null)
      this.$refs.cookiePanel.init()
    },
  }
}
</script>

<style lang="sass" scoped>
#app
  min-height: 100vh
  //width: 100vw
  display: flex
  overflow: hidden
  flex-direction: column
  font-family: $font-text
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.footer
  margin-top: auto
</style>

