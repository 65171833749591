<template>
  <b-navbar class="desktop-navigation py-0">
    <router-link :to="$i18nRoute({ name: 'Home'})">
      <b-navbar-brand class="pl-2 pt-2">
        <img alt="isotronic horizontal logo" height="30" src='@/assets/images/logos/logo-horizontal.svg'>
      </b-navbar-brand>
    </router-link>
    <b-navbar-nav class="ml-auto"
                  @mouseover="menuHover = true"
                  @mouseleave="menuHover = false"
    >
      <menu-dropdown
          :menu-item="menuItems[0]"
          :menu-hover="menuHover"
          :contact-hover="contactHover"
          @setOpened="setMenuOpen(0, $event)"
      >
        <b-row>
          <b-col
              v-for="(item, idx) in menuItems[0].menuItems"
              :key="item.title + idx"
              class="py-4 menu-item"
              cols="12"
          >
            <router-link :to="$i18nRoute({ name: item.page})" tag="div">
              <div class="i-primary i-font-text-bold menu-item-title">{{ item.title }}</div>
              <div class="i-secondary i-font-text-light menu-item-description mt-2">{{ item.description }}</div>
            </router-link>
          </b-col>
        </b-row>
      </menu-dropdown>
      <menu-dropdown
          :menu-item="menuItems[1]"
          :menu-hover="menuHover"
          :contact-hover="contactHover"
          @setOpened="setMenuOpen(1, $event)"
      >
        <b-row class="products">
          <b-col
              v-for="(item, idx) in menuItems[1].menuItems"
              :key="item.title + idx"
              class="py-4 menu-item"
              cols="6"
          >
            <router-link :to="$i18nRoute({ name: item.page})" tag="div">
              <b-row>
                <b-col cols="auto"
                       class="pl-3 pr-0 text-center"
                >
                  <b-img
                      fluid
                      :src="item.img.src"
                      class="product-img"
                  >
                  </b-img>
                </b-col>
                <b-col class="my-auto pl-2">
                  <div class="i-primary i-font-text-bold menu-item-title">
                    {{ item.title }}
                  </div>
                </b-col>
              </b-row>
            </router-link>
          </b-col>
        </b-row>
      </menu-dropdown>
      <menu-dropdown
          :menu-item="menuItems[2]"
          :menu-hover="menuHover"
          right
          :contact-hover="contactHover"
          @setOpened="setMenuOpen(2, $event)"
      >
        <b-row>
          <b-col
              v-for="(item, idx) in menuItems[2].menuItems"
              :key="item.title + idx"
              class="py-4 menu-item"
              cols="12"
          >
            <router-link :to="$i18nRoute({ name: item.page})" tag="div">
              <div class="i-primary i-font-text-bold menu-item-title">{{ item.title }}</div>
              <div class="i-secondary i-font-text-light menu-item-description mt-2">{{ item.description }}</div>
            </router-link>
          </b-col>
        </b-row>
      </menu-dropdown>
    </b-navbar-nav>
    <div class="mt-3"
         @mouseover="contactHover = true"
         @mouseleave="contactHover = false"
    >
      <callout-button
          link="Contact"
          variant="secondary"
          class="ml-2 mt-3">
        {{ $t("general.contact") }}
      </callout-button>
    </div>
  </b-navbar>
</template>

<script>
import MenuDropdown from "@/components/navigation/desktop/MenuDropdown"
import CalloutButton from "@/components/utility/CalloutButton";

export default {
  components: {
    MenuDropdown,
    CalloutButton
  },
  props: {
    menuItems: Array,
  },
  data() {
    return {
      menuHover: false,
      contactHover: false,
      imgSrc: ''
    }
  },
  methods: {
    setMenuOpen(index, val) {
      this.$emit('setMenuItemOpen', index, val)
    }
  }
}
</script>

<style lang="sass" scoped>
.desktop-navigation
  padding-left: 8px
  padding-right: 8px
  @media screen and (min-width: $sm)
    padding-left: 23px
    padding-right: 23px

.menu-item
  &:hover
    background-color: $less-red
    cursor: pointer

    .menu-item-title
      color: white
      text-decoration: none

    .menu-item-description
      color: white

    .product-img
      mix-blend-mode: screen

.product-img
  height: 80px
  width: 80px

.menu-item-title
  font-size: 20px
  line-height: 1.1 !important

.contact-us
  @include secondary-button

.products
  width: 530px

</style>

<style lang="sass">
.menu-dropdown-menu
  margin-top: 0 !important
  padding-top: 0 !important
  padding-bottom: 0 !important
</style>
